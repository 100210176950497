/* services.css */

.services-section-info {
  width: 100%;
  min-height: 350px;
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #505050;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
}

.services-section-info:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

.service-banner-container {
  position: relative;
  margin-top: 2rem;
  display: block;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.service-bannerImage-container {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%) rotate(-50deg) scaleX(-1);
  z-index: -2;
  max-width: 100%;
  filter: blur(15px);
}

.service-bannerImage-container img {
  width: 100%;
  height: auto;
  display: none;
  transform: rotate(20deg) scaleX(1);
}

.services-section-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.services-section-bottom {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  align-items: stretch;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .services-section-info {
    margin-bottom: 2rem;
  }

  .service-bannerImage-container {
    display: none;
  }
  
}

@media (min-width: 601px) and (max-width: 1279px) {
  .services-section-container {
    justify-content: center; /* Center the entire section horizontally */
    align-items: center; /* Center the entire section vertically */
  }

  .services-section-bottom {
    justify-content: center; /* Center the cards horizontally */
    align-content: center; /* Center the cards vertically */
  }
}

@media (min-width: 1280px) {
  .services-section-bottom {
    grid-template-columns: repeat(3, 1fr); /* 3x3 grid at larger resolutions */
  }
}
