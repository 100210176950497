/* CSS for About Component */

.about-section-container {
    margin-top: 20rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.about-background-image-container {
    position: absolute;
    left: -150px;
    scale: 1.2;
    z-index: -2;
}

.about-section-image-container {
    display: flex;
    flex: 2;
    /* Add margin between image and text container */
}

.about-section-image {
    /* Ensure the image doesn't exceed the container width */
    width: calc(100% - 2rem); /* Adjust image width for smaller screens */
}



.primary-subheading {
    font-weight: 700;
    color: #fe9e0d;
    font-size: 1.15rem;
}

.primary-heading {
    font-size: clamp(2rem, 5vw, 4rem);
    color: #4c4c4c;
    max-width: 600px;
}

.primary-text {
    font-size: clamp(1rem, 3vw, 1.5rem);
    max-width: 600px; /* Increase max-width for smaller screens */
    color: #6a6a6a;
    margin: 1.5rem 0rem;
}

.about-buttons-container {
    margin-top: 2rem;
    display: flex;
    justify-content: center; /* Center buttons horizontally */
    align-items: center; /* Center buttons vertically */
}

.secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
}

/* Add the following styles to your existing CSS */

.watch-video-button {
    background-color: #4c4c4c2f;
    padding-right: 30px;
    font-size: 1rem !important;
    
    transition: background-color 0.3s ease; /* Smooth transition effect */
}

.watch-video-button:hover {
    background-color: #333; /* Change background color on hover */
    color: #fff; /* Change text color on hover */
}

.watch-video-button svg {
    padding: 5px;
    margin-right: 0.5rem !important;
}


/* Responsive Styles */
@media (max-width: 950px) {
    .about-background-image-container {
        display: none;
    }
    .primary-subheading {
        margin-top: 40px;
    }

    .about-section-text-container {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        flex: 2;
        
        align-items: left; /* Center text container horizontally */
        text-align: left; /* Center text content */
    }
    .about-section-image {
        display: flex;
        flex: 1;
        max-width: 400px;
        width: 80%; /* Adjust image width for smaller screens */
    }
    .about-section-image-container {
        margin-right: 0; /* Remove margin between image and text container on smaller screens */
    }

    .watch-video-button {
       margin-top: 10px;
    
    }

    /* Center booking button in mobile view */
    .about-buttons-container {
        flex-direction: column;
        
        align-items: center;
    }
}
