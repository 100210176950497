/* BottomNavbar.css */

.bottom-navbar {
    position: fixed;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    max-width: 800px; /* Adjust the max-width as needed */
    background-color: #fff;
    color: #000;
    padding: 20px;
    text-align: center;
    transition: top 0.3s ease-in-out, background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow */
    z-index: 999; /* Ensure it's on top of other elements */
}

.bottom-navbar.hidden {
    top: -200px; /* Adjust the distance to move it off-screen */
}

.navbar-content {
    display: flex;
    
    justify-content: space-around;
    padding: 0.3rem;;
}


/* 
.navbar-content a:hover {
    color: #ffee10;
  box-shadow: 0 0 5px #ffee10;
  text-shadow: 0 0 5px #ffee10;
  } */


.navbar-content span {
    padding: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    margin: 0 5px;
}



/* BottomNavbar.css */

@media (max-width: 756px) {
    .bottom-navbar {
      display: none;
    }
}
