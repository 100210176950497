/* faq.css */

.faq-section-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  justify-content: center;
  margin-top: 5rem; /* Adjusted margin for better spacing */
}

.faq-section {
  width: 80%; /* Adjust the width as needed */
}

.primary-subheading-faq {
  text-align: left; /* Align subheading to the left */
  font-weight: 700;
  color: #fe9e0d;
  font-size: 1.15rem;
}

.primary-heading-faq  {
  text-align: left; /* Align heading to the left */
  font-size: clamp(2rem, 5vw, 4rem);
  color: #4c4c4c;
  max-width: 600px;
}

.faq-item {
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden; /* Ensure border-radius doesn't get cut off */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle box shadow */
}

.faq-question {
  width: 560px;
  cursor: pointer;
  
  display: flex;
  flex-direction: column; /* Stack question and icon vertically on mobile */
  padding: 15px;
  color: #1a1a1a; /* Dark text color for better readability */
  background-color: #f8f8f8;
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

.faq-question:hover {
  background-color: #e0e0e0; /* Lighter background on hover */
}

.faq-answer {
  width: 560px;
  padding: 15px;
  border-top: 1px solid #ddd; /* Separate answer from question with a subtle border */
}

/* Optional: Add transition effects for smoother interactivity */
.faq-answer,
.faq-item {
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.faq-item:hover {
  transform: translateY(-5px); /* Add a subtle lift effect on hover */
}

.faq-section-image-container {
  display: flex;
  flex: 1;
  max-width: 500px;
  margin-top: 160px;
  justify-content: center;
  text-align: center;
 margin-right: 0rem !important;
 }


.faq-section-image-container img {
  transition: transform 0.3s ease-in-out; /* Add a smooth transition for the transform property */
}

.faq-section-image-container img:hover {
  transform: scale(1.1) ;

}


/* Media Query for Mobile */
/* faq.css */

/* ... (Previous CSS rules) */

/* Media Query for Mobile */
@media screen and (max-width: 950px) {
  .faq-section {

    width: 100%; /* Full width for mobile */
  }

  .faq-item {
    width: 100%; /* Full width for each FAQ item on mobile */
    margin-bottom: 20px; /* Add spacing between FAQ items */
  }

  .faq-question,
  .faq-answer {
    width: 100%; /* Full width for both question and answer on mobile */
    max-width: 100%; /* Ensure it takes full width on smaller screens */
    margin: 0 auto; /* Center the content horizontally */
  }

  .faq-question {
    padding: 15px; /* Adjust padding for better spacing on mobile */
  }
  .faq-section-image-container {
    display: flex;
    flex: 1;
    max-width: 400px;
    margin-top: 50px;
    justify-content: center;
    text-align: center;
   margin-right: 0rem !important;
   }
  
  .faq-answer {
    padding: 15px;
    border-top: none; /* Remove the top border on mobile to keep a clean look */
  }
}
