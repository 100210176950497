* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Reem Kufi", sans-serif !important;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  max-width: 100%;
  overflow-x: hidden;
  background-color: #f6f6f6;
}

.App {
  min-height: 100vh;
  width: 85vw;
  
  max-width: 1900px;
  margin: 0rem auto;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
}

.navbar-menu-container {
  display: none;
}

.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: black;
  font-size: 1.05rem;
  font-weight: 400;
  transition: background-color 0.3s ease;
  padding: 0.5rem ; /* Add padding for better spacing */
  border-radius: 25px; /* Add border-radius for rounded corners */
}
.navbar-links-container a:hover {
  background-color: #f0f0f0; /* Change the background color on hover */
}
.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}

.navbar-cart-icon {
  font-size: 1.15rem;
}

.primary-button:hover {
  background-color: rgb(234, 234, 234);
}

.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}

.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 3rem;
}

.home-bannerImage-container {
  position: absolute;
  top: -100px;
  right: -170px;
  z-index: -2;
  max-width: 700px;
}

.home-image-section img {
  transition: transform 0.3s ease-in-out;
}

.home-image-section img:hover {
  transform: scale(1.1) rotate(5deg);
}

.home-image-section {
  max-width: 550px;
  flex: 1;
  scale: 1.05;
}

.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.primary-heading {
  font-size: clamp(2rem, 5vw, 4rem);
  color: #4c4c4c;
  max-width: 600px;
}

.primary-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 500px;
  color: #6a6a6a;
  margin: 1rem 0rem;
}

.secondary-button {
  padding: 1rem 2.5rem;
  background-color: #fe9e0d;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}

.secondary-button:hover {
  background-color: #e48f0f;
}

.about-section-image {
  width: 70%;
}

.about-section-image-container img {
  transition: transform 0.3s ease-in-out;
}

.about-section-image-container img:hover {
  transform: scale(1.1) rotate(-5deg);
}

.about-section-container {
  margin-top: 20rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about-background-image-container {
  position: absolute;
  left: -150px;
  scale: 1.2;
  z-index: -2;
}



.title-tag{
  font-weight: 700;
  color: #fe9e0d;
  overflow: hidden; /* Hide overflow to create a typing effect */
  white-space: nowrap; /* Keep the text on a single line */
  border-right: 2px solid transparent; /* Add a blinking cursor */
  animation: typing 2s steps(20, end), blink-caret 0.5s step-end infinite;

}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 50%;
  }
}




.primary-subheading {
  font-weight: 700;
  color: #fe9e0d;
  font-size: 1.15rem;
}

.about-buttons-container {
  margin-top: 2rem;
  display: flex;
}

.watch-video-button {
  margin-left: 2rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #484848;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.watch-video-button svg {
  font-size: 3rem;
  margin-right: 1rem;
}

.contact-page-wrapper h1 {
  max-width: 900px !important;
}

.contact-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 6rem 0rem;
}

.contact-form-container {
  background-color: white;
  max-width: 700px;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5rem;
}

.contact-form-container input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
}

.footer-title-tag{
  font-weight: 700;
  color: #fe9e0d;
}


.footer-wrapper {
  margin: 6rem 0rem;
  display: flex;
  margin-top: 10rem;
}

.footer-logo-container {
  max-width: 110px;
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer-icons {
  margin-top: 1.5rem;
}

.footer-icons svg {
  font-size: 1.5rem;
  margin-right: 1.25rem;
  color: #585858;
}

.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.footer-section-columns {
  display: flex;
  flex-direction: column;
  min-width: 190px;
}

.footer-section-columns span {
  margin: 0.25rem 0rem;
  font-weight: 400;
  font-size: 15px;
  color: #4c4c4c;
  cursor: pointer;
}
/* In your App.css or relevant styles file */
.preloader {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-in-out;
}

.preloader.loaded {
  opacity: 0;
  visibility: hidden;
}

.content {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out;
}

.content:not(.hidden) {
  opacity: 1;
  visibility: visible;
}

.testimonial-section-bottom {
  margin-top: 2rem;
  background-color: white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
}

.testimonial-section-bottom {
  margin: 2rem auto;
}

.testimonial-section-bottom p {
  font-weight: 700;
  color: #515151;
  max-width: 500px;
  font-size: 1.1rem;
  margin: 2rem 0rem;
}

.testimonials-stars-container svg {
  margin: 0rem 0.25rem;
  font-size: 1.5rem;
  color: #fe9e0d;
}

.testimonial-section-bottom h2 {
  margin-top: 1.5rem;
}

.work-section-wrapper {
  margin-top: 15rem;
}

.work-section-top p {
  text-align: center;
  max-width: 600px !important;
}

.work-section-top h1 {
  max-width: 700px !important;
}

.work-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.nav-logo-container {
  max-width: 100px;
  cursor: pointer;
}

.nav-section {
  position: fixed;
  width: 100%;
  background-color: white;
  margin: 0;
  padding: 0;
}

.nav-logo-container img {
  width: 80px;
  transition: transform 0.3s ease-in-out;
}

.nav-logo-container img:hover {
  transform: scale(0.95);
}

.work-section-info {
  width: 290px;
  min-height: 350px;
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #505050;
  margin: 1rem 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.work-section-info h2 {
  margin: 1rem 0rem;
  font-weight: 600;
}

.work-section-bottom {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.info-boxes-img {
  max-width: 100%;
  height: 150px;
}

.work-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  color: #919191;
}

.work-section-info:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .primary-button {
    font-size: 1rem;
  }
  .home-bannerImage-container {
   display: none;
  }
}

@media (max-width: 800px) {
  .nav-logo-container {
    max-width: 80px;
  }
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
  }
  .home-bannerImage-container {
    display: none;
   
  }
  .home-banner-container,
  .about-section-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .about-section-image {
    height: auto;
  }
  .about-section-container {
    flex-direction: column;
  }
  .home-image-section {
    width: 100%;
    max-width: 400px;
   
  }


  .primary-heading {
    text-align: center;
    max-width: 90%;
  }
  .primary-text {
    text-align: center;
    max-width: 80%;
  }
  .home-text-section {
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }

  @media (max-width: 768px) {
    .about-section-text-container {
      padding: 10px;
    }
    .about-section-image {
      width: auto;
    }
  }
  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  .about-section-container {
    margin-top: 5rem !important;
  }
  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
  }
  .primary-subheading {
    text-align: center;
  }
  .watch-video-button {
    margin-left: 0rem !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }
  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }
  .about-section-image-container {
    display: flex;
    flex: 1;
    margin-right: 0rem !important;
  }

  .work-section-wrapper {
    margin-top: 5rem !important;
  }
  .work-section-bottom {
    margin-top: 1rem !important;
  }
  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
  }
  .contact-form-container input {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }
  .footer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .footer-section-two {
    margin-top: 2rem;
  }
  
  .footer-section-columns {
    margin: 1rem 0rem;
  }
  
  .App {
    max-width: 95vw;
  }
}

/* Example Responsive Layout */
@media (max-width: 756px) {
  .footer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .footer-section-two {
    
    margin-top: 2rem;
  }
  
  .footer-section-columns {
    margin: 1rem 0rem;
  }

  .footer-section-columns {
    width: 100%; /* Make columns full-width on smaller screens */
  }
}


/* Add this to your CSS file */
.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.579);
  border-radius: 50%;
  border-top: 6px solid #fe9e0d; /* Adjust color as needed */
  width: 20px;
  margin-left: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
